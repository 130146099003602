<template>
  <!-- eslint-disable vue/no-v-html -->
  <Modal :is-open="isOpen" class="justify-center" @close="onModalClose">
    <div class="container">
      <div
        class="relative grid max-h-[80vh] grid-cols-12 items-center gap-y-9 overflow-auto rounded-lg bg-white px-6 pb-6 pt-16 md:p-16 lg:gap-x-8 lg:px-24 lg:py-16"
      >
        <button
          class="c-modal-action absolute right-5 top-5"
          @click="onModalClose"
        >
          <span class="sr-only">Close inclusion details</span>
          <UiIcon icon="close" size="md" />
        </button>

        <div
          class="col-span-full grid grid-cols-2 gap-2 lg:col-span-5 lg:max-w-[357px]"
        >
          <figure
            v-for="(image, imageIndex) in images"
            :key="image.id"
            :class="{
              'col-span-2 w-full': imageIndex === 0,
            }"
          >
            <NuxtImg
              :src="image.filename"
              :alt="image.alt"
              :class="{
                'h-[254px]': imageIndex === 0,
                'h-[220px]': imageIndex > 0,
              }"
              width="360"
              class="size-full rounded-lg object-cover"
            />
          </figure>
        </div>

        <div class="col-span-full flex flex-col gap-5 lg:col-span-7">
          <h2 class="text-lg font-bold md:text-2xl">
            {{ inclusion.title }}
          </h2>

          <div
            class="c-description-list text-gray-700"
            v-html="descriptionList"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import type { BlockInclusionItemStoryblok } from '@/types/storyblok'

const props = defineProps<{
  inclusion: BlockInclusionItemStoryblok
  isOpen: boolean
}>()

const descriptionList = computed(() =>
  renderRichText(props.inclusion.descriptionList),
)
const images = computed(() => props.inclusion.images.slice(0, 3))

const emit = defineEmits<{
  close: []
}>()

function onModalClose(): void {
  emit('close')
}
</script>

<style scoped lang="postcss">
.c-modal-action {
  @apply ease-out-expo text-gray-500 transition-colors duration-700 hover:text-gray-700;

  &.is-active {
    @apply text-opacity-100;
  }
}

.c-description-list {
  :deep(ul) {
    @apply divide-y divide-gray-300 border-y border-gray-300;

    li {
      @apply flex items-center py-4;

      &::before {
        @apply block bg-no-repeat py-2 pl-10 content-[''];
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%2300843D' d='m10 13.6 5.9-5.9a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-6.6 6.6c-.2.2-.433.3-.7.3a.96.96 0 0 1-.7-.3l-2.6-2.6a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l1.9 1.9Z'/%3E%3C/svg%3E");
        background-position: top 50% left 4px;
      }
    }
  }
}
</style>

<template>
  <div v-editable="blok" class="container px-5 pb-[--blockSpacing] lg:pb-0">
    <Swiper
      :slides-per-view="1.45"
      :slides-offset-before="20"
      :slides-offset-after="20"
      :breakpoints="swiperBreakpoints"
      space-between="10px"
      touch-events-target="container"
      class="!overflow-visible px-5"
    >
      <SwiperSlide v-for="inclusion in inclusionCards" :key="inclusion._uid">
        <CardCallToAction :card="inclusion" @click="onClick(inclusion)" />
      </SwiperSlide>
    </Swiper>

    <InclusionDetails
      v-if="selectedInclusion"
      :is-open="isInclusionDetailsVisible"
      :inclusion="selectedInclusion"
      @close="toggleInclusionDetails(false)"
    />
  </div>
</template>

<script setup lang="ts">
import type { SwiperOptions } from 'swiper/types'
import screens from '#tailwind-config/theme/screens'
import type {
  BlockInclusionItemStoryblok,
  BlockPlanInclusionsStoryblok,
  ContentTypePropertyStoryblok,
  PageSectionStoryblok,
} from '@/types/storyblok'
import type { CardCallToAction } from '@/components/card/CardCallToAction.vue'

const props = defineProps<{
  blok: BlockPlanInclusionsStoryblok
  pageSectionTheme: PageSectionStoryblok['theme']
  pageSectionSpacing: PageSectionStoryblok['spacing']
}>()

const { story } = storeToRefs(useCurrentStoryStore())

const { propertyConfigInclusions } = usePropertyData(
  story.value?.content as ContentTypePropertyStoryblok,
)

const inclusions = computed(() =>
  props.blok.inclusions?.length
    ? props.blok.inclusions
    : propertyConfigInclusions.value,
)
const inclusionCards = computed<CardCallToAction[]>(
  () =>
    inclusions.value?.map((inclusion) => ({
      _uid: inclusion._uid,
      title: inclusion.title,
      image: inclusion.images?.[0],
    })) || [],
)

const [isInclusionDetailsVisible, toggleInclusionDetails] = useToggle(false)
const selectedInclusion: Ref<BlockInclusionItemStoryblok | undefined> = ref(
  inclusions.value?.[0],
)
const swiperBreakpoints = ref<SwiperOptions['breakpoints']>({
  [stripPx(screens.sm)]: {
    slidesPerView: 2.5,
    spaceBetween: 30,
  },
  [stripPx(screens.lg)]: {
    slidesPerView: 4.6,
    spaceBetween: 30,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
  },
})

const onClick = ({ _uid }: CardCallToAction) => {
  selectedInclusion.value = inclusions.value?.find((i) => i._uid === _uid)
  toggleInclusionDetails()
}
</script>
